<template>
  <div>
    <el-dialog
      title="我要购买"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
      center
    >
      <div class="image_box">
        <el-image
          style="width: 100%"
          :src="require('@/assets/onbuy1.png')"
        ></el-image>
      </div>
      <el-form label-width="100px" :model="form" ref="form" :rules="rules">
        <el-form-item label="您的姓名:" prop="name">
          <el-input
            v-model="form.name"
            placeholder=""
            style="width: 60%"
          ></el-input>
        </el-form-item>
        <el-form-item label="您的电话:" prop="phone">
          <el-input
            v-model="form.phone"
            placeholder=""
            style="width: 60%"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称:" prop="gongsiName">
          <el-input
            v-model="form.gongsiName"
            placeholder=""
            style="width: 60%"
          ></el-input>
        </el-form-item>
        <el-form-item label="管理需求:" prop="demand">
          <el-input
            v-model="form.demand"
            placeholder=""
            style="width: 60%"
          ></el-input>
        </el-form-item>
      </el-form>
      <p class="download" @click="downloadDoc">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 下载评估表
      </p>
      <div class="bottom">
        <p @click="handleClose">取消</p>
        <p class="submit" @click="submit">立即预约</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { submit } from "@/Api/indexAPI";
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        name: "",
        phone: "",
        gongsiName: "", //公司名字
        demand: "", //需求
      },
      rules: {
        name: [{ required: true, trigger: "blur", message: "请输入姓名" }],
        phone: [{ required: true, trigger: "blur", message: "请输入手机号" }],
        gongsiName: [
          { required: true, trigger: "blur", message: "请输入公司名字" },
        ],
        demand: [{ required: true, trigger: "blur", message: "请输入需求" }],
      },
    };
  },
  methods: {
    submit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let { name, phone, gongsiName, demand } = this.form;
          let pams = {
            name,
            phone,
            companyName: gongsiName,
            yourRequest: demand,
          };
          const res = await submit(pams);
          if (!res.ok) {
            this.$notify.error({
              title: "错误",
              message: res.msg,
            });
            return false;
          }
          this.handleClose();
          this.$notify({
            title: "成功",
            message: "恭喜你提交成功",
            type: "success",
          });
        }
      });
    },

    handleClose() {
      this.dialogVisible = false;
      this.$refs["form"].resetFields();
    },
    show() {
      this.dialogVisible = true;
    },

    downloadDoc() {
      // 创建一个<a></a>标签
      const a = document.createElement("a");
      a.href = "./documents/评估表.rar";
      a.download = "评估表.rar";
      // 障眼法藏起来a标签
      a.style.display = "none";
      // 将a标签追加到文档对象中
      document.body.appendChild(a);
      // 模拟点击了<a>标签,会触发<a>标签的href的读取,浏览器就会自动下载了
      a.click();
      // 一次性的,用完就删除a标签
      a.remove();
    },
  },
};
</script>



<style lang="scss" scoped>
::v-deep .el-dialog__title {
  font-weight: 900;
  font-size: 24px;
}

::v-deep .el-dialog__body {
  //   padding: 0 !important;
  padding: 10px !important;
}

.image_box {
  padding: 0 26px 0 26px;
}
.bottom {
  position: absolute;
  display: flex;
  right: 0;
  bottom: 0;
  padding: 10px 10px 20px 10px;
  p {
    padding: 6px 30px 6px 30px;
    background-color: #98af67;
    cursor: pointer;
  }

  .submit {
    margin-left: 10px;
    background-color: #90c422;
  }
}

.download {
  color: #3e56f4;
  cursor: pointer;
  margin-bottom: 50px;
}
</style>