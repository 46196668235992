<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="20%"
      :before-close="handleClose"
      center
    >
      <el-image
        style="width: 100%"
        :src="require('@/assets/Twoimensional.jpg')"
      ></el-image>
    </el-dialog>
  </div>
</template>

<script>
import { submit } from "@/Api/indexAPI";
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },

    handleClose() {
      this.dialogVisible = false;
      //   this.$refs["form"].resetFields();
    },
  },
};
</script>



<style lang="scss" scoped>
</style>