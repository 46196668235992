<template>
  <div>
    <h2>{{ title }}</h2>
    <div class="AdvantageBeautify">
      <div class="AdvantageBeautify_top">
        <div></div>
        <p>{{msg}}</p>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title","msg"],
};
</script>

<style lang="scss" scoped>

  h2 {
    margin-top: 47px;
    text-align: center;
    font-size: 36px;
    // font-size: 36px;
  }

  .AdvantageBeautify {
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .AdvantageBeautify_top {
      display: flex;
      align-items: center;
      div {
        width: 377px;
        height: 6px;
        background: #c3c3c3;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
      p {
        width: 300px;
        text-align: center;
        font-weight: 400;
        color: #999999;
        font-size: 22px;
        padding: 0 20px 0 20px;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          z-index: 1;
          width: 129px;
          height: 7px;
          background: #90c422;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          left: 105px;
          bottom: -20px;
        }
      }
    }
  }

</style>