<template>
  <div class="home">
    <!-- 头部呜 -->
    <div class="nav">
      <div class="nav_cent">
        <div>
          <el-image
            style="height: 65px"
            :src="require('@/assets/LOGO1.png')"
          ></el-image>
        </div>
        <ul>
          <li v-for="item in title" @click="onTwoimensional(item)" :key="item">
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
    <div class="body">
      <div class="show">
        <el-image
          style="width: 100%"
          :src="require('@/assets/banner.png')"
        ></el-image>
      </div>
      <!-- 代理合作 -->
      <div class="ConsumptionTendency">
        <ul>
          <li @click="onbuy">我要购买</li>
          <li>我要代理</li>
          <li>我要合作</li>
        </ul>
      </div>
      <!-- 行业优势 -->
      <!-- <div class="advanWidthtage">
        <Advantage :title="`行业优势`" :msg="`INDUSTRY ADVANTAGES`" />
        <div class="list_box">
          <div v-for="item in list" :key="item.name" class="list_item">
            <div class="list_item_top">
              <el-image style="width: 62px" :src="item.icon"></el-image>

              <div class="list_item_tight">
                <h2>{{ item.name }}</h2>
                <p>{{ item.msg }}</p>
              </div>
            </div>
          </div>
          <p style="width: 31%; max-width: 371px"></p>
        </div>
      </div> -->

      <!-- 系统介绍 -->
      <div class="system">
        <Advantage :title="`系统介绍`" :msg="`SYSTEM INTRODUCTION`" />

        <div class="system_box">
          <h2>行业细分领域超深度定制 SaaS 系统</h2>
          <p>
            星达美雅美业深度管理 SAAS 平台，将业内具有 20
            多年连锁管理实战企业的整体管理运营、销售服务、产品经验等内容，全面地通过系统得以标准化呈现，对店院管理中老板端、门店端、店长端、员工端、客户端进行深度定制。将系统导入的难度与收益周期分别降低
            80%和提升
            60%。最大化限度地降低了企业与员工的系统学习运用成本，据实际线下场景分别开发独立应用端，使在单独使用的同时后台数据还能实时联动，可以在第一时间为一线流程参与者和管理层提供有效精准的产品服务、员工、用户、需求的完整画像。把程序思维与管理思维做到了动态平衡。可以在极短的时间为企业培训合格的美容老师，24
            小时追踪用户及员需求;并对产品服务的销售出单进行模型分析，最终达到最佳搭配及最佳产出比。为单店及连锁生活美容类企业的管理，连锁化发展提供保障。
          </p>
        </div>
      </div>

      <!-- 系统优势 -->
      <div class="systemAdvantage">
        <Advantage :title="`系统优势`" :msg="`SYSTEM ADVANTAGES`" />
        <div class="systemAdvantage_box">
          <div class="box_cent">
            <ul class="ul_left">
              <li>
                <h2>留老增新</h2>
                <p>留住存量老客户</p>
                <p>开发增量新客户用核心科技掌握未来</p>
              </li>
              <li class="li_bottom">
                <h2>掌握市场</h2>
                <p>24 时掌握市场脉搏</p>
                <p>项畅销，产品畅销一目了然</p>
              </li>
            </ul>
            <el-image
              style="width: 30%"
              :src="require('@/assets/circle.png')"
            ></el-image>

            <ul class="ul_right">
              <li>
                <h2>算法技术</h2>
                <p>CRM 算法+AIOT 技术</p>
                <p>明明白白客户需求</p>
              </li>
              <li>
                <h2>精准分析</h2>
                <p>100%数据可视化呈现精准分析</p>
                <p>杜绝客户数据与员工数据清晰不模</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- 行业痛点 -->
      <div class="PainSpot">
        <Advantage :title="`行业痛点`" :msg="`SINIOd NIVd ALSNANI`" />
        <div class="PainSpot_box">
          <p>您的企业是否还经历着..</p>
          <ul>
            <li v-for="item in tongdianList" :key="item.icon">
              <p>{{ item.msg }}</p>
              <p>{{ item.msg1 }}</p>
              <p>{{ item.msg2 }}</p>
              <div>{{ item.icon }}</div>
            </li>
          </ul>
        </div>
      </div>

      <!-- 产品优势 -->
      <div class="Product">
        <Advantage :title="`产品优势`" :msg="`INDUSTRY ADVANTAGES`" />
        <div class="Product_centen">
          <div class="Product_tight">
            <el-image style="width: 100%" :src="require('@/assets/pc1.png')">
            </el-image>
            <el-image
              class="pc_position"
              :src="require('@/assets/pc_position.png')"
            ></el-image>
          </div>
          <div class="Product_left">
            <p>建立<span>100% </span> 稳定的渠道合作</p>
            <p class="msg">Stable channel cooperation</p>
            <p class="act"></p>
            <h6>
              人脸识别技术是通过观察人们所具有的自然性的脸部生物特征来进行身份的确认，识别
              <br />
              方式十分便捷，用户无需携带任何证件或额外进行其他操作，只需站在设备旁十几秒
              就
              <br />能完成扫描识别，没有他人冒充的风险。可以更快捷的捕捉到用户的微表情来获取最新
              <br />
              的用户情绪。
            </h6>
          </div>
        </div>
      </div>

      <!-- 合作企业 -->

      <div class="firm">
        <Advantage :title="`合作企业`" :msg="`COOPERATIVES`" />

        <div class="block">
          <el-carousel height="450px">
            <el-carousel-item v-for="item in 3" :key="item">
              <h3 class="small"></h3>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <!-- 常见问题 -->
      <div class="FAQ">
        <div class="FAQ_item">
          <el-image
            v-if="!PhoneFlag"
            class="icon_top"
            style="width: 72px"
            :src="require('@/assets/icon/maohao.png')"
          ></el-image>
          <el-image
            v-if="!PhoneFlag"
            class="icon_bottom"
            style="width: 72px"
            :src="require('@/assets/icon/maohao.png')"
          ></el-image>
          <h2>
            还有不确定的地方吗? 点击下方
            <span>常见问题</span>
            页面将为你解答所有常见问题，或者直接在线联系我们，我们
            <br />
            将竭力为您服务!
          </h2>
          <p>
            is there anything else unsure? Click on the FAQ page below to answer
            alyour frequently asked questions, or contact us directly online
            with us <br />
            Will do our best to serve you!
          </p>
          <div class="button_box">
            <el-button type="success" plain>常见问题</el-button>
          </div>
          <div class="circle_box">
            <div class="circle_1"></div>
            <div class="circle_2"></div>
            <div class="circle_3"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 尾部 -->
    <div class="foot">
      <div class="foot_cent">
        <div class="foot_left">
          <el-image
            class="foot_left_image"
            style="height: 55px"
            :src="require('@/assets/LOGO2.png')"
          ></el-image>

          <div class="foot_left_ipho">
            <p>固定电话: 400-040-9168</p>
            <h6 @click="beian">
              版权所有: 星达美雅科技咨询有限公司 &emsp; &emsp;
              <span>琼ICP备2023000251号</span>
            </h6>
          </div>
        </div>

        <div class="foot_right">
          <p>违法不良信息举报电话: 400-040-9168</p>
          <h6>举报邮箱: 164468862@qq.com</h6>
        </div>
      </div>
    </div>

    <Onbuy ref="Onbuy" />
    <Twoimensional ref="Twoimensional" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import Advantage from "@/components/Advantage.vue";
//二维码
import Twoimensional from "@/components/Twoimensional.vue";
//我要购买
import Onbuy from "@/components/onbuy.vue";

import { dongtai } from "@/Api/indexAPI";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
    Advantage,
    Onbuy,
    Twoimensional,
  },
  data() {
    return {
      PhoneFlag: false,
      title: ["首页", "关于我们", "业务合作", "联系我们"],
      list: [
        {
          name: "美容院",
          icon: require("@/assets/icon/meirong.png"),
          msg: "beauty parlor",
        },
        {
          name: "养生馆",
          icon: require("@/assets/icon/yangsheng.png"),
          msg: "Wellness Hall",
        },

        {
          name: "皮肤管理",
          icon: require("@/assets/icon/pifu.png"),
          msg: "Skin management",
        },
        {
          name: "拓客团队",
          icon: require("@/assets/icon/tuoke.png"),
          msg: "Tuoke team",
        },
        {
          name: "我想创业",
          icon: require("@/assets/icon/chuangye.png"),
          msg: "I want to start a business",
        },
      ],
      tongdianList: [
        {
          msg: "竞争同质化严重",
          msg1: "客户流失严重",
          msg2: "渠道粘性差",
          icon: "厂家",
        },
        {
          msg: "上游厂家压力大",
          msg1: "下游店院业绩差",
          msg2: "店院粘性差",
          icon: "代理商",
        },
        {
          msg: "客户留不住",
          msg1: "员工留不住",
          msg2: "竞争压力大",
          icon: "美容院",
        },
      ],
    };
  },
  created() {
    // dongtai().then((res) => {
    //   console.log(res);
    // });

    this.PhoneFlag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
  },

  methods: {
    onbuy() {
      this.$refs.Onbuy.show();
    },
    onTwoimensional(item) {
      if (item == "业务合作" || item == "联系我们") {
        this.$refs.Twoimensional.show();
      }
      // this.$refs.Onbuy.show();
    },

    beian() {
      window.open("https://beian.miit.gov.cn/");
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  // width: 1920px;
  margin: 0 auto;
  height: 65px;
  background: #90c422;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  overflow-y: hidden;
  .nav_cent {
    width: 100%;
    max-width: 1183px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    ul {
      width: 50%;
      max-width: 500px;
      display: flex;
      // justify-content: space-between;
      justify-content: space-around;
      align-items: center;
      li {
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}

.foot {
  // width: 1920px;
  margin: 0 auto;
  height: 55px;
  background: #141c26;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  overflow-y: hidden;

  .foot_cent {
    width: 100%;
    max-width: 1183px;
    // width: 70%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .foot_left {
      width: 50%;
      display: flex;
      .el-image__inner {
        margin-top: -10px;
      }

      .foot_left_ipho {
        margin-left: 20px;
        color: #666666;
        overflow: hidden;
        font-size: 12px;
        p {
          margin-top: 12px;
          margin-bottom: 2px;
        }
        h6 {
          span {
            cursor: pointer;
          }
        }
      }
    }
    .foot_right {
      font-size: 12px;
      color: #666666;
      p {
        margin-top: 12px;
        margin-bottom: 2px;
      }
    }
  }
}
.body {
  // width: 1920px;
  margin: 0 auto;
  width: 100%;
  // overflow-y: hidden;
  overflow: hidden;
  .ConsumptionTendency {
    display: flex;
    justify-content: center;
    ul {
      // width: 50%;
      width: 800px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 65px;
      li {
        font-weight: bold;
        color: #90c422;
        cursor: pointer;
      }
    }
  }

  .show {
    display: flex;
    justify-content: center;
  }

  // 系统介绍
  .system {
    background-color: #f2f3f7;
    padding: 40px 0 60px 0;
    .system_box {
      width: 100%;
      text-align: center;
      max-width: 1183px;
      margin: 80px auto;

      h2 {
        padding-bottom: 40px;
        font-size: 28px;
      }
      p {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
  // 系统优势
  .systemAdvantage {
    .systemAdvantage_box {
      width: 100%;
      max-width: 1183px;
      margin: 80px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .box_cent {
        display: flex;
        justify-content: space-between;
        width: auto;

        .ul_left {
          text-align: right;
        }
        ul {
          width: 33%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          p {
            color: #7b7b7b;
            font-size: 20px;
            margin-top: 8px;
          }
        }
      }
    }
  }

  // 行业痛点
  .PainSpot {
    background-color: #f2f3f7;
    padding: 40px 0 60px 0;
    .PainSpot_box {
      width: 100%;
      max-width: 1183px;
      margin: 80px auto;
      p {
        text-align: center;
        font-size: 24px;
        color: #939393;
      }
      ul {
        margin-top: 100px;
        display: flex;
        justify-content: space-between;
        li {
          width: 31%;
          background: linear-gradient(
            #d4fc80,
            #c0eb65,
            #85b917
          ); /* 标准的语法（必须放在最后） */
          position: relative;
          padding: 4% 0 4% 0;
          border-radius: 10px;

          p {
            color: #ffffff;
            font-size: 22px;
            margin-bottom: 10px;
            font-weight: 900;
          }

          div {
            position: absolute;
            right: 0;
            top: 0;
            color: #ffffff;
            font-size: 22px;
            font-weight: 900;
            background-color: #90c422;
            padding: 6px 20px 8px 20px;
            border-bottom-left-radius: 30px;
          }
        }
      }
    }
  }

  .advantage {
    background: #f2f3f7;
    overflow: hidden;
    .list_box {
      width: 100%;
      max-width: 1183px;
      margin: 80px auto;
      display: flex;
      justify-content: space-between;

      flex-wrap: wrap;
      .list_item {
        // width: 371px;
        width: 31%;
        max-width: 371px;
        height: 158px;
        background: #ffffff;
        border-radius: 9px 9px 9px 9px;
        opacity: 1;
        overflow: hidden;
        margin-top: 30px;
        &:last-child {
          justify-self: flex-start;
        }
        .list_item_top {
          margin: 20px 0 0 20px;
          display: flex;

          .list_item_tight {
            margin-left: 20px;
            h2 {
              font-size: 24px;
              font-weight: bold;
            }
            p {
              font-size: 12px;
              font-weight: 400;
              color: #999999;
              line-height: 16px;
              position: relative;
              &:after {
                content: "";
                position: absolute;
                z-index: 1;
                bottom: -4px;
                left: 0;
                width: 34px;
                height: 2px;
                background: #c3c3c3;
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .Product {
    .Product_centen {
      width: 100%;
      max-width: 1183px;
      margin: 100px auto;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      justify-content: space-around;

      .Product_left {
        // width: 50%;
        p {
          font-size: 34px;
          font-weight: bold;
          span {
            color: #90c422;
          }
        }
        .msg {
          font-size: 24px;
          font-weight: 400;
          color: #999999;
          margin: 10px 0 30px 0;
        }

        .act {
          width: 104px;
          height: 6px;
          background: #999999;
          border-radius: 5px 5px 5px 5px;
          opacity: 1;
          margin-bottom: 20px;
        }
        h6 {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
      }

      .Product_tight {
        width: 50%;
        position: relative;
        .pc_position {
          position: absolute;
          z-index: 10;
          right: 1.7%;
          width: 63%;
          top: 3%;
        }
        // height: auto;
        // background-image: url("../assets/pc.png");
        // background-size: 100% 100%;
      }
    }
  }
  .firm {
    overflow: hidden;
    background: #f2f3f7;

    .block {
      width: 100%;
      max-width: 1183px;
      margin: 60px auto;
      .small {
        height: 100%;
        background-color: #fff;
        // height: 450px;
      }
    }

    ::v-deep .el-carousel__indicator--horizontal .el-carousel__button {
      width: 10px;
      height: 10px;
      background: #cacaca;
      border: 1px solid #cacaca;
      border-radius: 50%;
      opacity: 0.5;
    }
    ::v-deep
      .el-carousel__indicator--horizontal.is-active
      .el-carousel__button {
      width: 10px;
      height: 10px;
      background: #90c422;
      border-radius: 50%;
      opacity: 1;
    }
  }

  .FAQ {
    width: 100%;
    max-width: 1183px;
    margin: 0 auto;
    padding-top: 130px;
    padding-bottom: 60px;

    .FAQ_item {
      position: relative;

      .icon_top {
        position: absolute;
        left: -40px;
        top: -60px;
      }

      .icon_bottom {
        position: absolute;
        right: 5px;
        // bottom: -30px;
        top: 90px;
      }
      h2 {
        color: #999999;
        font-size: 24px;
        text-align: center;
        font-weight: 400;
        span {
          color: #90c422;
        }
      }
      p {
        margin-top: 20px;
        text-align: center;
        font-size: 14px;
        color: #c5c5c5;
        font-weight: 400;
      }

      .button_box {
        padding-top: 40px;
        display: flex;
        justify-content: center;
        .is-plain {
          border: 2px solid #90c422;
          background: #ffffff;
          font-size: 24px;
          color: #90c422;
        }
      }

      .circle_box {
        .circle_1 {
          width: 27px;
          margin: 0 auto;
          margin-top: 40px;
          height: 27px;
          background: #c9e58e;
          border-radius: 50%;
          opacity: 1;
        }
        .circle_2 {
          margin: 20px auto;
          width: 44px;
          height: 44px;
          background: #b4d276;
          border-radius: 50%;
          opacity: 1;
        }

        .circle_3 {
          margin: 0px auto;
          width: 82px;
          height: 83px;
          background: #90c422;
          border-radius: 50%;
          opacity: 1;
        }
      }
    }
  }
}
</style>
