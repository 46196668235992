import { get, post } from "@/utils/http";


let url =
  process.env.NODE_ENV === "development"
    ? "https://api.sindamiya.com"
    : "https://api.sindamiya.com";

// let url =
//   process.env.NODE_ENV === "development"

//   ? "https://binhe.tymicrocode.com"
//    : "http://182.254.223.102:5001"
//      ;


// 我要购买提交
export const submit = (parmas) =>
    get(`${url}/apisys/Sell/ForAsk`,parmas);